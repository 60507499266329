import { useLayoutEffect, useRef } from 'react';
import { stargateTheme } from '../../styles/theme';
import { DownloadWalletBanner } from "../banners";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Main } from "../main";

export const StargateWidget = () => {
  const containerRef = useRef();
  const navigateToTutorial = () =>
    window.open('https://pontem.network/posts/how-to-bridge-tokens-to-aptos-buy-apt-on-liquidswap');

  const [storageDownloadValue, setStorageDownloadValue] = useLocalStorage(
    "showDownloadWalletBanner",
    "true"
  )

  useLayoutEffect(() => {
    const bridge = containerRef.current

    customElements.whenDefined('aptos-bridge').then(() => {
      // @ts-ignore
      const { config } = bridge;

      config.setChains([101, 102, 106, 108, 109, 110, 111]); // see https://layerzero.gitbook.io/docs/technical-reference/mainnet/supported-chain-ids
      // optional
      config.setTokens(['ETH', 'WETH', 'USDC', 'USDT', 'APT', 'doodoo']);
      // optional

      config.setWallets(['Pontem', 'Martian', 'MetaMask', 'Petra', 'CoinBase']);
      // @ts-ignore
      bridge?.setTheme(stargateTheme);
    });
  }, []);
  return (
    <Main>
      <div>
        <div className="tutorial-container">
          <p>Read the Tutorial before bridging</p>
          <button className="tutorial-button" onClick={navigateToTutorial}>
            Tutorial
          </button>
        </div>
        <div className="widget_parent">
          {/* @ts-ignore */}
          <aptos-bridge ref={containerRef} />
        </div>
        <DownloadWalletBanner />
      </div>
    </Main>
  );
};
